// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("bootstrap") // July 5, 2020 - I may have to move this to its own pack in the future


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


require("trix")
require("@rails/actiontext")

document.addEventListener("turbolinks:load", function(){
  userDate = new Date();
  const day = userDate.getDate();
  const month = userDate.getMonth() + 1;
  const year = userDate.getFullYear();

  let daySelect = document.getElementById("_date_3i");
  let monthSelect = document.getElementById("_date_2i");
  let yearSelect = document.getElementById("_date_1i");

  let mealDaySelect = document.getElementById("meal_item_meal_date_3i");
  let mealMonthSelect = document.getElementById("meal_item_meal_date_2i");
  let mealYearSelect = document.getElementById("meal_item_meal_date_1i");

  let restaurantDaySelect = document.getElementById("restaurant_meal_item_meal_date_3i");
  let restaurantMonthSelect = document.getElementById("restaurant_meal_item_meal_date_2i");
  let restaurantYearSelect = document.getElementById("restaurant_meal_item_meal_date_1i");

  let homeMadeMealDaySelect = document.getElementById("home_made_meal_item_meal_date_3i");
  let homeMadeMealMonthSelect = document.getElementById("home_made_meal_item_meal_date_2i");
  let homeMadeMealYearSelect = document.getElementById("home_made_meal_item_meal_date_1i");

  let inventoryItemDaySelect = document.getElementById("inventory_item_date_purchased_3i");
  let inventoryItemMonthSelect = document.getElementById("inventory_item_date_purchased_2i");
  let inventoryItemYearSelect = document.getElementById("inventory_item_date_purchased_1i");

  if(daySelect){
    daySelect.value = day;
    monthSelect.value = month;
    yearSelect.value = year;
  } else if(mealDaySelect) {
    mealDaySelect.value = day;
    mealMonthSelect.value = month;
    mealYearSelect.value = year;
  } else if(restaurantDaySelect) {
    restaurantDaySelect.value = day;
    restaurantMonthSelect.value = month;
    restaurantYearSelect.value = year;
  } else if(homeMadeMealDaySelect) {
    homeMadeMealDaySelect.value = day;
    homeMadeMealMonthSelect.value = month;
    homeMadeMealYearSelect.value = year;
    } else if(inventoryItemDaySelect) {
    inventoryItemDaySelect.value = day;
    inventoryItemMonthSelect.value = month;
    inventoryItemYearSelect.value = year;
    }
});
